import React from "react"
import {
  Content,
  FeaturedImage,
  FrontMatter,
  Layout,
  Subscribe,
} from "../components"
import {Link} from "gatsby"
import {P} from "../elements"
import SEO from "../components/SEO"
import ParseHTML from "../utils/ParseHTML"
const SinglePostTemplate = ({ pageContext }) => {
  const {
    title,
    date,
    author,
    categories,
    featured_media,
    content,
  } = pageContext
  return (
    <Layout backgroundColor="light" hasHeader={false}>
      <SEO title={title} description={content} />
      <FeaturedImage
        categories={categories}
        fluid={featured_media.localFile.childImageSharp.fluid}
      />

      <Content>
        <Link to="/tactics"><P margin="0 0 26px 0" black="black">&#8249; Go Back</P></Link>
        <FrontMatter title={title} author={author} date={date} />
        {ParseHTML(content)}
      </Content>

      <Subscribe />
    </Layout>
  )
}
export default SinglePostTemplate
